<template>
  <div>
    <!-- modal de confirmation -->
    <b-modal
      id="modal-no-footer"
      ref="myModal"
      title="Confirmation"
      centered
      modal-class="modal-danger"
      ok-variant="danger"
      cancel-title="Non"
      cancel-variant="outline-secondary"
      ok-title="Oui"
      @ok="updateFormEvaluation"
    >
      <b-card-text>
        Attention : Une auto-évaluation existe déjà pour ce sous-secteur. Souhaitez-vous écraser l'ancienne et enregistrer
        la nouvelle ?
      </b-card-text>
    </b-modal>

    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          {{ $t("configuration.evalations_forms.title") }}<br>
        </h2>
        <!-- <b-button
          v-b-modal.modal-center
          class="mt-1 float-right my-auto col-3"
          variant="primary"
          @click="() => { }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Nouveau Formulaire</span>
        </b-button> -->
      </div>
      <hr>

      <!-- Modal du formulaire d'evaluation -->
      <b-modal
        id="modal-center"
        centered
        title="Formulaire d'évaluation"
        ok-only
        ok-title="Enregistrer"
        @ok="createEvaluation"
      >
        <b-form>
          <b-form-group
            class="col pr-0 pl-0"
            label="Secteur de l'auto évaluation"
          >
          <template v-slot:label>
                <span>Secteur de l'auto évaluation
                  <RequiredStar />
                </span>
              </template>
            <b-input-group class="w-100">
              <v-select
                v-model="secteurId"
                class="w-100"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="(option) => option.id"
                :options="listSectorsData"
                placeholder=""
                @input="handleSecteurChange"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            class="col pr-0 pl-0"
            label="Type de Demande"
          >
          <template v-slot:label>
                <span>Type de Demande
                  <RequiredStar />
                </span>
              </template>
            <b-input-group class="w-100">
              <v-select
                v-model="evaluation.type_auto_evaluation_id"
                class="w-100"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="(option) => option.id"
                :options="allTypeEvaluation"
                placeholder=""
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            class="col pr-0 pl-0"
            label="Secteur d'activité"
          >
          <template v-slot:label>
                <span>
                    Secteur d'activité
                    <RequiredStar />
                </span>
              </template>
            <b-input-group class="w-100">
              <v-select
                v-model="evaluation.type_etablissement_id"
                class="w-100"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="(option) => option.id"
                :options="allTypeEtablissement"
                placeholder=""
              />
            </b-input-group>
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- filter -->
      <!-- {{ filterBy }} -->
      <b-row>
        <b-col lg="6">
          <!-- type_auto_evaluation-->
          <div class="d-flex flex-row-reverse nopadding">
            <b-form-group
              class="col pr-0 pl-0"
              label="Type de l'auto évaluation"
            >
              <b-input-group class="w-100">
                <v-select
                  v-model="filterBy.type_auto_evaluation_id"
                  class="w-100"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="(option) => option.id"
                  :options="options_type_evaluation"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col lg="6">
          <!-- secteur_id-->
          <div class="d-flex flex-row-reverse nopadding">
            <b-form-group
              class="col pr-0 pl-0"
              label="Secteur de l'auto évaluation²"
            >
              <b-input-group class="w-100">
                <v-select
                  v-model="filterBy.secteur_id"
                  class="w-100"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="(option) => option.id"
                  :options="listSectorsData"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <!-- <b-col lg="6">
          <div class="d-flex flex-row-reverse nopadding">
            <b-form-group
              class="col pr-0 pl-0"
              :label="'Type de l\'auto évaluation'"
            >
              <b-input-group class="w-100">
                <v-select
                  class="w-100"
                  v-model="filterBy.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="(option) => option.id"
                  :options="options_type_secteur_hebergement"
                  placeholder=""
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
      </b-col>-->
        <b-col lg="12">
          <!-- button -->
          <div class="d-flex flex-row-reverse nopadding mt-1">
            <b-button
              class="float-right"
              variant="warning"
              @click="load_data()"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-25"
              />
              <span>{{ $t("button.search") }}</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!-- <app-collapse class="nopadding mt-2">
        <app-collapse-item :title="$t('user_manage.filter')">
        </app-collapse-item>
      </app-collapse> -->
    </b-card>

    <!-- <b-card>
        content of : {{ tableData }}
    </b-card> -->

    <b-card>
      <div class="row">
        <b-card
          :title="$t('Forms')"
          class="col-12"
        >
          <b-table
            ref="refFormsTable"
            :items="tableData"
            responsive
            :fields="tableColumns"
            primary-key="id"
            show-empty
            empty-text="Aucune auto-évaluation"
            :busy="isLoading"
            class="pt-4 pb-5"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <template #cell(type)="data">
              <h5>
                <b-badge
                  pill
                  :variant="'dark'"
                >{{
                  data.item.type === "evaluation" ? "Évaluation" : "Examen"
                }}</b-badge>
              </h5>
            </template>
            <template #cell(secteur_id)="data">
              {{ data.item.secteur && data.item.secteur.title }}
            </template>
            <template #cell(date)="data">
              {{ reverseDate(data.item.created_at) }}
            </template>
            <template #cell(action)="data">
              <!-- <b-button
                @click="
                  () => {
                    go_to_detail();
                  }
                "
                variant="primary"
                class="mx-0"
                size="sm"
              >
                <span class="text-nowrap">
                  <i class="las la-eye"></i>
                </span>
              </b-button> -->
              <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Sélectionner un type de sous secteur pour voir le formulaire associé '
                "
                text="Primary"
                variant="primary"
                dropup
              >
                <!-- content button -->
                <template #button-content>
                  <i class="las la-eye" />
                </template>
                <b-dropdown-item
                  v-for="(type, t_index) in data.item.formulaires"
                  :key="t_index"
                  @click="go_to_detail(type.type_auto_evaluation_id, type.type_etablissement.id)"
                >
                  {{ type.type_etablissement.title }} {{ type.class }} {{ type.class !== null ? (type.class === 1 ? 'étoile' : 'étoiles') : '' }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BTable,
  BCard,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormCheckbox,
  BModal,
  VBModal,
  BAlert,
  BCardText,
  BFormRadioGroup,
  BSpinner,
  BCardHeader,
  BCardBody,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, /*mapGetters,*/ mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import { VueEditor } from 'vue2-editor'

// -------- store modules and vuex utilities
import evaluationsStoreModule from '@/store/evaluations'
import secteursStoreModule from '@/store/secteurs'
import etablissementsStroreModule from '@/store/etablissements'
// import appConfigurationStroreModule from '@/store/app-configuration'
import RequiredStar from "@/components/RequiredStar.vue";


import {
  registerStoreModule,
  // unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  components: {
    quillEditor,
    BForm,
    BTable,
    BCard,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
    BModal,
    vSelect,
    VBModal,
    BAlert,
    BSpinner,
    BCardText,
    BFormRadioGroup,
    BCardHeader,
    BTabs,
    BTab,
    BCardBody,
    VueEditor,
    ToastificationContent,
    AppCollapse,
    AppCollapseItem,
    BDropdown,
    BDropdownItem,
    RequiredStar
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      secteurId: null,
      evaluationData: null,
      allFormEvaluation: [],
      allTypeEtablissement: [],
      allTypeEvaluation: [],
      searchFormEvaluationData: [],
      oldFormEvaluationId: null,
      evaluation: {
        title: '',
        type_auto_evaluation_id: null,
        type_etablissement_id: null,
        data:
        {
          "chapters": [
      {
        "n": 1,
        "label": "Information et réservation",
        "sections": [
          {
            "id": 1,
            "label": "Information",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Une information sur l'établissement est disponible en ligne, au niveau d'un site Web dédié, des plateformes de réservation, des réseaux sociaux…. L'information communiquée par l'établissement (services proposés, photos, téléphone, etc.) doit être conforme à la réalité et éviter toute mention pouvant induire le client en erreur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un site Web contenant une information exhaustive sur l'établissement (emplacement, services offerts, conditions de réservation, photos, horaires d'ouverture, etc.). Le contenu du site Web de l'établissement (services proposés, photos, numéro de téléphone, etc.) doit être conforme à la réalité et éviter toute mention pouvant induire le client en erreur.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le site Web de l'établissement est développé au minimum en deux langues, dont le français et l'anglais.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La localisation (coordonnées GPS) de l'établissement est facilement accessible en ligne, en particulier via un référencement au niveau des principaux services de cartographie en ligne.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Possibilité d'appeler l'établissement pour se renseigner sur ses services 24h/24.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Réservation",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement propose la possibilité d'effectuer des réservations en ligne",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement propose la possibilité d'effectuer une réservation par téléphone",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les réservations effectuées sont confirmées par un courriel qui comprend, entre autres, les informations suivantes : la date d'arrivée et de départ, le nombre de nuitées, le plan d'accès à l'établissement, le prix convenu, tout éventuel supplément ou taxe à payer, les conditions d'annulation de la réservation, un numéro d'appel.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 2,
        "label": "Extérieur",
        "sections": [
          {
            "id": 1,
            "label": "Parking et dépose",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un parking et/ou d'un garage réservé aux clients de l'établissement et permettant un accès direct à l'entrée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Pour la catégorie 5 étoiles, le nombre d'emplacements du parking est conforme aux ratios suivants : un emplacement pour 5 unités de logement + 1 emplacement pour 8 places au niveau des salles de séminaires. Pour les établissements déjà opérationnels au jour de publication des normes, un écart de 20% par rapport à ces ratios est toléré.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Pour la catégorie 4 étoiles, le nombre d'emplacements du parking est conforme aux ratios suivants : un emplacement pour 8 unités de logement + 1 emplacement pour 10 places au niveau des salles de séminaires. Pour les établissements déjà opérationnels au jour de publication des normes, un écart de 20% par rapport à ces ratios est toléré.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Pour la catégorie 3 étoiles, le nombre d'emplacements du parking est conforme aux ratios suivants : un emplacement pour 10 unités de logement + 1 emplacement pour 12 places au niveau des salles de séminaires. Pour les établissements déjà opérationnels au jour de publication des normes, un écart de 20% par rapport à ces ratios est toléré.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le parking est bien éclairé, de jour comme de nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le parking est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le sol du parking dispose d'un revêtement adéquat, sécurisé et en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les emplacements de stationnement sont signalés et bien visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le parking est surveillé, de jour comme de nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Un ou plusieurs emplacements sont réservés aux personnes à mobilité réduite et sont signalés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une zone de dépose-minute devant l'entrée de l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "La zone de dépose-minute est abritée des intempéries",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de voiturier bien visible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "La tenue du/des voiturier(s) est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "La tenue du/des voiturier(s) est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un ou plusieurs espaces verts d'une superficie minimale de 200 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Espaces verts",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les espaces verts de l'établissement sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les espaces verts de l'établissement sont propres et bien entretenus",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les déchets organiques sont valorisés en compostes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Enseigne",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une enseigne comportant le nom de l'établissement et bien visible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'enseigne est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'enseigne est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'enseigne est éclairée la nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Autre",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les façades et autres éléments extérieurs de l'établissement sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les façades et autres éléments extérieurs de l'établissement sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Lorsque l'établissement est éclaté en plusieurs bâtiments, une signalétique indiquant ses différentes composantes existe",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 3,
        "label": "Accueil et réception",
        "sections": [
          {
            "id": 1,
            "label": "Entrée",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'entrée de l'établissement est privative (elle n'est pas commune avec d'éventuels commerces, logements résidentiels, etc. situés dans le même bâtiment)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le client accède directement au hall de l'établissement, sans devoir traverser d'autres services de ce dernier",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'entrée des clients est séparée de celle du personnel et de la marchandise",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'entrée de l'établissement est bien éclairée, de jour comme de nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Un bagagiste est constamment disponible à l'entrée de l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition des chariots à bagages propres et en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Lorsque la porte d'entrée ne s'ouvre pas automatiquement à l'approche d'un client, un portier existe constamment",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La tenue des bagagistes et des portiers est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La tenue des bagagistes et des portiers est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Hall d'accueil et salons",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement comporte un hall d'accueil dont la superficie minimale est de 80 m² pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement comporte un hall d'accueil dont la superficie minimale est de 60 m² pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'établissement comporte un hall d'accueil dont la superficie minimale est de 20 m² pour l'hôtel 2 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement comporte un hall d'accueil dont la superficie minimale est de 10 m² pour l'hôtel 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le sol, les murs et plafonds du hall d'accueil sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol, les murs et plafonds du hall d'accueil sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le hall d'accueil est climatisé. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'ensemble du hall et des salons sont équipés d'une connexion WIFI, d'un débit descendant minimum de 10 Mb/s pour les catégories 3*, 4* et 5*",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "L'ensemble du hall et des salons sont équipés d'une connexion WIFI, d'un débit descendant minimum de 4 Mb/s pour les catégories 2*",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le hall d'accueil et les salons ouverts comportent des assises de type fauteuils, chaises… et des tables basses selon les ratios suivants : 0,3 places assises par chambre pour l'hôtel 5 étoiles, avec un minimum de 25 places assises",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le hall d'accueil et les salons ouverts comportent des assises de type fauteuils, chaises… et des tables basses selon les ratios suivants : 0,25 places assises par chambre pour l'hôtel 4 étoiles, avec un minimum de 20 places assises  ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le hall d'accueil et les salons ouverts comportent des assises de type fauteuils, chaises… et des tables basses selon les ratios suivants : 0,2 places assises par chambre pour l'hôtel 3 étoiles, avec un minimum de 10 places assises",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Le hall d'accueil et les salons ouverts comportent au minimum cinq (5) places assises (cas de la catégorie 2*).",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Le hall d'accueil et salons sont harmonieusement décorés (exemple : tableaux, plantes, sculptures, fleurs, etc.) et non-encombrants",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "Une musique d'ambiance (musique de fond) est diffusée par l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "Le mobilier et les éléments décoratifs du hall d'accueil et des salons sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "Le mobilier et les éléments décoratifs du hall d'accueil et des salons sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 18,
                "input_type": "RADIO",
                "question": "Un distributeur de billets de banques est disponible au niveau ou à proximité du hall de l'établissement. Le distributeur accepte au minimum deux types de cartes internationales",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 19,
                "input_type": "RADIO",
                "question": "L'établissement interdit de fumer dans le hall d'accueil. Un affichage dans ce sens est bien visible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 20,
                "input_type": "RADIO",
                "question": "L'accès aux différents services et points de vente que comporte l'établissement est signalé grâce à un affichage adéquat",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Réception",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un espace de réception identifié grâce à un affichage et comportant un comptoir, un bureau ou une table",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre minimum d'heures de disponibilité de la réception est conforme aux ratios suivants : 24 heures par jour pour les hôtels 5 étoiles et 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le nombre minimum d'heures de disponibilité de la réception est conforme au ratio suivant : 16 heures par jour pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le nombre minimum d'heures de disponibilité de la réception est conforme au ratio suivant : 12 heures par jour pour l'hôtel 2 étoiles ; 10 heures par jour pour l'hôtel 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le nombre minimum d'heures de disponibilité de la réception est conforme au ratio suivant : 10 heures par jour pour l'hôtel 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement affiche les prix des différentes catégories d’unités de logement ainsi que toute autre taxe ou charge supplémentaire à supporter par le client",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le paiement par carte bancaire internationale est possible (au minimum deux types de cartes bancaires internationales)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement affiche les moyens de paiement acceptés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition une documentation relative aux principales attractions touristiques au niveau de sa zone d'implantation",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les informations sur les attractions touristiques sont disponibles au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Dans le cas où le règlement de l'hôtel prévoit que les objets d'une certaine valeur doivent être déposés au coffre de la réception, un coffre-fort dédié doit être aménagé au niveau de cette dernière, même en cas d'existence de coffres-forts individuels dans les unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition des adaptateurs électriques sur demande. Le nombre minimum d'adaptateurs doit être équivalent à 5% du nombre d’unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Le mobilier de la réception (comptoir, bureau, chaises…) est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Le mobilier de la réception (comptoir, bureau, chaises…) est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "La réception est bien rangée et non-encombrée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "Le personnel de la réception porte un uniforme/une tenue commune",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "Le personnel de la réception parle au moins deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 18,
                "input_type": "RADIO",
                "question": "Le personnel de la réception parle au moins trois langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 19,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la réception est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 20,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la réception est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Conciergerie",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un service de conciergerie indiqué",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'espace de conciergerie est séparé de la réception",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'espace de conciergerie est bien rangé et non-encombré",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le personnel de la conciergerie porte un uniforme/tenue",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la conciergerie est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la conciergerie est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "En sus de la langue française, le personnel de la conciergerie parle au moins deux autres langues étrangères dont l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement offre la possibilité d'effectuer un change de devises et dispose des autorisations nécessaires pour ce faire. Le taux de change est affiché et bien visible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de transfert depuis/vers l'aéroport international le plus proche",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "L'établissement propose la possibilité d'appeler/réserver un taxi",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de location de voitures avec ou sans chauffeur (directement ou indirectement à travers un partenariat avec des entreprises de transport) ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "L'établissement offre la possibilité de faire une impression ou d'obtenir une photocopie",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de réveil",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de couture/retouche",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de cirage des chaussures",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "L'établissement propose un service de billetterie/réservation d'excursions, visites, spectacles, moyens de transport…",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "L'établissement propose la possibilité d'effectuer des courses à l'extérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Bagagerie",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une bagagerie fermée et sécurisée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La bagagerie compartimentée, comportant des étagères et/ou des casiers",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 4,
        "label": "Sanitaires communs",
        "sections": [
          {
            "id": 1,
            "label": "Aspect général",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Des sanitaires communs sont aménagés par l'établissement, à proximité du hall/salon",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les sanitaires communs sont séparés entre hommes et femmes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Des sanitaires communs adaptés aux personnes à mobilité réduite sont aménagés par l'établissement, à proximité du hall/salon",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les sanitaires communs disposent d'un système efficace de ventilation ou d'extraction de l'air",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "En cas d'utilisation d'un système de ventilation/extraction mécanique, il ne doit pas être bruyant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol, les murs, les plafonds et les portes des sanitaires sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le sol, les murs, les plafonds et les portes des sanitaires sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les sanitaires communs sont harmonieusement aménagés et non-encombrants",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les sanitaires sont bien éclairés, de jour comme de nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Cabines de toilette",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les sanitaires communs disposent de cabines de toilettes dont le nombre minimal est égal à 8 cabines pour l'hôtel 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les sanitaires communs disposent de cabines de toilettes dont le nombre minimal est égal à 6 cabines pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les sanitaires communs disposent de cabines de toilettes dont le nombre minimal est égal à 4 cabines pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les sanitaires communs disposent de cabines de toilettes dont le nombre minimal est égal à 2 cabines pour l'hôtel 2 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les portes des cabines des toilettes permettent une isolation complète de celles-ci par rapport au reste du bloc sanitaire (absence de vide au-dessous/dessus de la porte",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'aménagement des toilettes prévoit deux espaces libres de 20 cm minimum de chaque côté et au minimum 30 cm (catégories 1 à 3 étoiles) ou 40 cm minimum (catégorie 4 et 5 étoiles) devant le siège de toilette",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La toilette (cuvette, siège, base, réservoir, couvercle…) est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La toilette (cuvette, siège, base, réservoir, couvercle…) est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable. Les supports en plastique ne sont acceptés que pour les catégories 1* et 2*",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "L'ensemble des cabines de toilettes disposent de poubelles en matière inoxydable avec couvercle. Les poubelles en plastique ne sont acceptées que pour les catégories 1* et 2*. A partir de la catégorie 3*, l'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont dotées de patères ou crochets en matière inoxydable derrière la porte. Les crochets/patères en plastique ne sont acceptés que pour les catégories 1* et 2*",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont dotées de distributeurs de sachets hygiéniques",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "La toilette est équipée d'un dispositif de rinçage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Les conduits et la tuyauterie au niveau des cabines de toilettes ne sont pas visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "Utilisation de chasse d'eau à double débit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Urinoirs",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les sanitaires pour hommes disposent d'urinoirs (au minimum 3)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les urinoirs sont aménagés de manière à assurer l'intimité des usagers",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les urinoirs sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les urinoirs sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Lavabo/vasque",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les sanitaires publics contiennent des lavabos/vasques de taille suffisante pour se laver les mains confortablement, dont le nombre minimal est égal à 8 pour l'hôtel 5 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les sanitaires publics contiennent des lavabos/vasques de taille suffisante pour se laver les mains confortablement, dont le nombre minimal est égal à 6 pour l'hôtel 4 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les sanitaires publics contiennent des lavabos/vasques de taille suffisante pour se laver les mains confortablement, dont le nombre minimal est égal à 4 pour l'hôtel 3 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les sanitaires publics contiennent des lavabos/vasques de taille suffisante pour se laver les mains confortablement, dont le nombre minimal est égal à 2 pour l'hôtel 2 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La hauteur des lavabos/vasque est comprise entre 85 et 90 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La plomberie des lavabos n'est pas visible, sauf lorsqu’elle fait partie des éléments décoratifs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les lavabos permettent de disposer de l'eau froide et chaude. L'eau chaude arrive dans un délai maximum de 10 secondes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont équipés en sèche-mains ou distributeurs de serviettes à usage unique. Les serviettes à usage multiple ne sont pas admis",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont équipés en sèche-mains ou distributeurs de serviettes à usage unique. Les serviettes à usage multiple ne sont pas admis",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le papier essuie-main est proposé dans un distributeur approprié fait en matière inoxydable autre que le plastique (en cas d'absence de sèche-main électrique)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Tous les lavabos/vasques sont dotés de miroirs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "La taille des miroirs est adaptée à celle du lavabo/vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Les robinets sont équipés d'économiseurs d'eau non-visibles ou ont un débit maximum ne dépasse pas 8 lites/minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques et l'ensemble de leurs composantes et accessoires sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques et l'ensemble de leurs composantes et accessoires sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 5,
        "label": "Circulation",
        "sections": [
          {
            "id": 1,
            "label": "Couloirs",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Tous les couloirs et allées ont une largeur minimale de 140 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les couloirs comportent une signalétique relative aux divers services de l'établissement et aux numéros des unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les couloirs sont bien éclairés, de jour comme de nuit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les couloirs sont harmonieusement décorés (objets d'art, jeu de lumière, objets d'art/artisanat…)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le sol des couloirs menant aux unités de logement permet de réduire le bruit des déplacements et d'impact grâce à un revêtement adapté",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les couloirs ne sont pas encombrés par des éléments qui n'en font pas partie",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les couloirs sont dotés de détecteurs de présence",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les couloirs et les allées sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les couloirs et les allées sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Ascenseurs",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un ou de plusieurs ascenseur(s) réservé(s) aux clients de l'établissement à partir du premier étage (catégories 4* et 5*)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un ou de plusieurs ascenseur(s) réservé(s) aux clients de l'établissement à partir du deuxième étage (catégorie 3*)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un ou de plusieurs ascenseur(s) réservé(s) aux clients de l'établissement à partir du troisième étage (1* et 2*)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La capacité de transport en simultané des ascenseurs est conforme aux ratios suivants : 10% du nombre d'unités de logements situées au 1er étage ou au-dessus pour les catégories 5 étoiles et 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La capacité de transport en simultané des ascenseurs est conforme aux ratios suivants : 10% du nombre d'unités de logements situées au 2ème étage ou au-dessus pour la catégorie 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La capacité de transport en simultané des ascenseurs est conforme aux ratios suivants : 10% du nombre d'unités de logements situées au 3ème étage ou au-dessus pour les catégories 2 étoiles et 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les ascenseurs comportent une signalétique indiquant l'accès aux unités de logement ainsi qu'aux différents services de l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Existence d'un ascenseur de service à partir du premier étage (catégories 4* et 5*)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Existence d'un ascenseur de service à partir du 2ème étage (catégories 3*)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les ascenseurs sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Les ascenseurs sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 6,
        "label": "Petit déjeuner",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le petit-déjeuner est servi dans un espace dédié (qui peut être le restaurant)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La surface minimale de l'espace/salle du petit-déjeuner est de1 m² par unité de logement pour l'hôtel 5 étoiles, avec un minimum de 100 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La surface minimale de l'espace/salle du petit-déjeuner est de0,8 m² par unité de logement pour l'hôtel 4 étoiles, avec un minimum de 80 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La surface minimale de l'espace/salle du petit-déjeuner est de 0,6 m² par unité de logement pour l'hôtel 3 étoiles, avec un minimum de 50 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La surface minimale de l'espace/salle du petit-déjeuner est de 0,3 m² par unité de logement pour l'hôtel 2 étoiles, avec un minimum de 30 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond de l'espace dédié au petit-déjeuner sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond de l'espace dédié au petit-déjeuner sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement sert le petit-déjeuner dans une terrasse (en plus de la salle de petit déjeuner)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le petit-déjeuner peut être servi dans la chambre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La salle du petit-déjeuner est climatisée. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La salle du petit-déjeuner est harmonieusement décorée (exemple : tableaux, plantes, sculptures, fleurs, etc.)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 1,
            "label": "Menu",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le nombre minimum de gammes du petit-déjeuner est de 13 pour l'hôtel 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre minimum de gammes du petit-déjeuner est de 11 pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le nombre minimum de gammes du petit-déjeuner est de 9 pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le nombre minimum de gammes du petit-déjeuner est de 6 pour l'hôtel 2 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Lorsque le petit déjeuner est de type buffet, les noms des produits proposés sont mentionnés à l'aide d'un affichage approprié.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Lorsque le petit déjeuner est de type buffet, les noms des produits proposés sont mentionnés à l'aide d'un affichage approprié au minimum en deux langues, dont le français et l'anglais.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Lorsque le petit déjeuner ne consiste pas en un buffet, un menu/carte existe. Le menu/carte doit spécifier les prix applicables ainsi que tout supplément (taxe, frais de services…) et être propre et en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Une partie des produits servis au petit-déjeuner est d'origine locale (minimum 10%)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Au minimum trois produits servis au petit-déjeuner sont issus de l'agriculture biologique (labellisés ou certifiés).",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Equipement et mobilier",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La salle/espace de petit-déjeuner dispose d'un minimum de trois chaises adaptées aux enfants",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, tables de service, aires de buffet…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, tables de service, aires de buffet…) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les couverts, la vaisselle et la verrerie sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les couverts, la vaisselle et la verrerie sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont en bon état.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le personnel chargé du petit-déjeuner porte un uniforme/une tenue commune",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La tenue du personnel du petit-déjeuner est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La tenue du personnel du petit-déjeuner est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le personnel du petit-déjeuner parle au moins deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les langues parlées par le personnel du petit-déjeuner sont facilement identifiables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 7,
        "label": "Restaurant(s)",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un restaurant principal",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre minimum de couverts est équivalent à 60% du nombre d'unités de logement (catégorie 5 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le nombre minimum de couverts est équivalent à 50% du nombre d'unités de logement (catégorie 4 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le nombre minimum de couverts est équivalent à 30% du nombre d'unités de logement (catégorie 3 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le nombre minimum de mètres carrés par couvert est de 1,7 pour l'hôtel 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le nombre minimum de mètres carrés par couvert est de 1,5 pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le nombre minimum de mètres carrés par couvert est de 1 pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un deuxième restaurant thématique avec un service de midi ou du soir",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les horaires des services des restaurants sont affichés à un endroit bien visible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "L'établissement assure un service de déjeuner et de dîner 7j/7",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le sol, les murs et les plafonds du/des restaurant(s) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le sol, les murs et les plafonds du/des restaurant(s) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Les restaurants sont climatisés (sauf les restaurants extérieurs). La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Le restaurant est harmonieusement décoré (exemple : tableaux, plantes, sculptures, fleurs, etc.)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Equipement et mobilier",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les restaurants disposent d'un minimum de trois chaises adaptées aux enfants",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, tables de service, aires de buffet…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, tables de service, aires de buffet…) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les couverts, la vaisselle et la verrerie sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les couverts, la vaisselle et la verrerie sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 1,
            "label": "Menu",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Existence d'un menu/carte des plats servis, spécifiant les prix applicables ainsi que tout supplément (taxe, frais de services…), propre et en bon état.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le menu/carte des plats servis est rédigé au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Lorsqu'un buffet est proposé, les noms des produits servis sont mentionnés à l'aide d'un affichage approprié",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Lorsqu'un buffet est proposé, les noms des produits servis sont mentionnés à l'aide d'un affichage approprié au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le restaurant propose au moins huit entrées différentes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le restaurant propose au moins huit plats différents",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le restaurant propose au moins six desserts différents",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Le restaurant propose au moins six boissons fraiches différentes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Au minimum deux plats locaux sont proposés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Au minimum un menu végétarien est proposé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le personnel du restaurant porte un uniforme/une tenue commune",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La tenue du personnel du restaurant est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La tenue du personnel du restaurant est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le personnel du restaurant parle au moins deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le personnel du restaurant parle au moins trois langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les langues parlées par le personnel du restaurant sont facilement identifiables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 8,
        "label": "Bar",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un bar, séparé du restaurant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du bar sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du bar sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le bar est climatisé. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le bar est harmonieusement décoré (exemple : tableaux, plantes, sculptures, fleurs, etc.)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Carte de boissons",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Une carte de boissons propre et en bon état existe. Outre la liste des boissons, elle comporte les prix applicables ainsi que tout supplément ou taxe à payer par le client",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La carte de boissons est rédigée au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le bar propose au moins dix variantes de vins et quatre variantes de bière",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le bar propose au minimum six variantes de cocktails",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le bar propose au minimum quatre variantes de boissons chaudes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le bar propose au minimum quatre variantes de boissons fraiches sans alcool (en dehors de l'eau)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le bar propose au minimum deux variantes de jus pressés minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Equipement et mobilier",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les boissons sont servies dans des verres/tasses adaptés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les équipements du bar sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les équipements du bar sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La verrerie est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La verrerie est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les tables, assises et comptoir sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les tables, assises et comptoir sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le personnel du bar porte un uniforme/une tenue commune",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La tenue du personnel du bar est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La tenue du personnel du bar est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le personnel du bar parle au moins deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le personnel du bar parle au moins trois langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les langues parlées par le personnel du bar sont facilement identifiables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 9,
        "label": "Salle(s) de séminaires, de réunions et de cérémonies",
        "sections": [
          {
            "id": 1,
            "label": "Salle polyvalente",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une ou de plusieurs salles polyvalentes d'une superficie minimale globale de 200 m²",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La salle polyvalente est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La salle polyvalente est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La salle polyvalente est climatisée. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La salle polyvalente est bien éclairée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La salle polyvalente est dotée d'un ou de plusieurs écrans de projection dont la taille est adaptée à l'espace",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La salle polyvalente est dotée d'un système de sonorisation en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La salle polyvalente est dotée d'une connexion WIFI d'un débit minimum descendant de 10 Mb/s",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "L'établissement offre la possibilité de faire des impressions et des photocopies directement depuis la salle polyvalente",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les équipements de la salle polyvalente sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Les équipements de la salle polyvalente sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Salles de réunion",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose de salles de réunion (minimum deux salles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les salles de réunion sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les salles de réunion sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La/Les salles de réunion sont climatisées. La température doit varier entre 21 et 24 °C.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les salles de réunion sont bien éclairées",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les salles de réunion sont dotées d'un ou de plusieurs écrans de projection dont la taille est adaptée à l'espace",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les salles de réunion sont dotées d'une connexion WIFI d'un débit minimum descendant de 10 Mb/s",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement offre la possibilité de faire des impressions et des photocopies directement depuis les salles de réunion",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les équipements des salles de réunions sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les équipements des salles de réunions sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 10,
        "label": "Bien-être et fitness",
        "sections": [
          {
            "id": 1,
            "label": "Accueil et information",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un centre de bien-être et fitness offrant au moins quatre services de type massage, hammam, salle de fitness, sauna, jacuzzi, etc.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et fitness dispose d'un espace d'accueil et d'information des clients avec un comptoir ou un bureau et des chaises",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'établissement assure un affichage des prix des différentes prestations ainsi que toute autre taxe ou charge supplémentaire à supporter par le client",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement exige le renseignement d'une fiche relative à la santé des clients, dans le respect des règles de confidentialité des données personnelles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le personnel de la réception porte un uniforme/une tenue commune",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le personnel du centre de bien-être et fitness parle au moins deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le personnel du centre de bien-être et fitness parle au moins trois langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la réception est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La tenue du personnel de la réception est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et fitness met à disposition un linge approprié (peignoir spa, linge de massage, serviette de la salle de fitness, linge de draps de piscine, le cas échéant…)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le linge du centre de bien-être et fitness est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le linge du centre de bien-être et fitness est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Massage",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement comporte au moins deux salles de massage, dotées de tables de massages, serviettes, système d’éclairage adapté…",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les salles de massage disposent d'un point d'eau chaude et froide et d'un lavabo à l'intérieur avec savon liquide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les salles de massage disposent d'un système leur permettant leur aération, à défaut d'existence d'une fenêtre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les salles de massage sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les salles de massage sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les masseurs/masseuses disposent d'une formation dans le domaine",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "L'établissement propose le choix entre au moins trois types de massages",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Hammam",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et fitness comporte un ou plusieurs hammams",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les murs, le sol et plafond du hammam sont revêtus en marbre, en céramique, en petit carrelage ou en peinture spéciale",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le hammam dispose d'assises, d'un banc ou une dalle construite et revêtue en marbre, en céramique, en petit carrelage ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le hammam dispose d'une fontaine permettant de mélanger l'eau chaude et froide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le hammam est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le hammam est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Un service de gommage est proposé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Sauna",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et fitness comporte un ou plusieurs saunas en bois et doté de pierres brulantes, d’une louche, d’un seau d’eau et d’un thermostat.   ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le sauna dispose d'un bouton d'appel d'urgence",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Sauna(s) propre(s)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Sauna(s) en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Jacuzzi",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et de fitness comporte un jacuzzi (bain à remous)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le jacuzzi est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le jacuzzi est en bon",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 6,
            "label": "Salon de beauté",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et de fitness comporte un salon de beauté proposant au moins quatre services de type coiffure, manucure, pédicure, soin de visage, épilation…",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le salon de beauté est climatisé. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le salon de beauté est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le salon de beauté est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Equipement du salon de beauté aseptisé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 7,
            "label": "Salle de fitness",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une salle de fitness comportant au moins six types d'appareils (pour les catégories 4* et 5*) ou quatre types d'appareils (pour la catégorie 3*), dont des appareils de cardio-training et des appareils de musculation",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La salle de fitness est équipée d'un grand miroir permettant de se voir en entier",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La salle de fitness est équipée d'un système sonore",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La salle de fitness est climatisée. La température doit varier entre 21 et 24 °C.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La salle de fitness est bien aérée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Des serviettes individuelles sont mises à disposition au niveau de la salle de fitness",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "L'eau fraiche est mise à disposition via une fontaine et des gobelets à usage unique ou des bouteilles d'eau individuelles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Un moniteur ou une monitrice est disponible au moins 8 heures par jour",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La salle de fitness est ouverte durant au minimum 14 heures par jour",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La salle de fitness est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Salle de fitness est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 8,
            "label": "Vestiaires",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le centre de bien-être et fitness dispose de vestiaires séparés entre hommes et femmes. Les critères du vestiairene sont pas applicables si 1) la salle de sport n'est pas ouverte aux clients externe et 2) l'établissement ne dispose pas de centre de bien-être",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés en assises (bancs, chaises…)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés en casiers/armoires avec système de fermeture (cadenas, code, clé, etc.)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés en crochets ou patères",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés de poubelles en matière inoxydable avec couvercle. Les poubelles en plastique ne sont pas acceptées. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol des vestiaires est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les vestiaires sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les vestiaires sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 9,
            "label": "Douches",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Chaque vestiaire dispose d'au moins deux cabines de douche. Les douches ne sont pas nécessaires lorsque 1) l'établissement ne dispose pas d'une salle de fitness ouverte aux clients externes et 2) ne dispose pas de centre de bien-être",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les cabines de douche ont une dimension minimale de 90cmx90cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les cabines de douche disposent d'un système de verrouillage de l'intérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le sol de la douche est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La douche est dotée d'eau chaude et froide. L'eau chaude arrive dans un délai maximal de 10 secondes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La hauteur du pommeau de douche est ajustable (lorsqu'elle n'est pas fixée au plafond)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La douche est dotée d'un porte-savonnette",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La cabine de douche comporte un crochet ou une patère",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Tous les éléments de la douche sont en matière inoxydable, autre que le plastique.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "L'eau ne déborde pas lors de l'utilisation de la douche.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La cabine de douche est bien éclairée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Les douches sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Les douches sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 10,
            "label": "Lavabo",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Chaque vestiaire contient au moins un lavabo ou une vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La hauteur des lavabos/vasque est comprise entre 85 et 90 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La plomberie des lavabos n'est pas visible, sauf lorsqu’elle fait partie des éléments décoratifs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont dotés d'un distributeur de savon liquide. Le savon solide est prohibé. les flacons ou bouteilles de savon liquide en plastique ne sont pas admis dans les catégories 4* et 5*",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont dotés de miroirs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La taille des miroirs est adaptée à celle du lavabo/vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les robinets sont dotés d'économiseurs d'eau non-visibles ou ont un débit maximum inférieur à 8 litres par minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 11,
            "label": "Cabines de toilette",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le vestiaire dispose d'au moins une cabine de toilette pour homme et une pour femmes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont toutes dotées d'une séparation du reste du vestiaire. Pour les catégories 5* et 4*, les portes des cabines de toilettes permettent une isolation complète de celle-ci par rapport au reste du bloc sanitaire",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'aménagement des toilettes prévoit deux espaces libres de 20 cm minimum de chaque côté et 40 cm minimum devant les WC",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les toilettes sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les toilettes sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable autre que du plastique",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable autre que du plastique",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'ensemble des cabines de toilettes disposent de poubelles avec couvercle, en matière inoxydable autre que du plastique. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les toilettes sont équipées d'un dispositif de rinçage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les conduits et la tuyauterie au niveau des cabines de toilettes ne sont pas visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Utilisation de chasse d'eau à double débit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 11,
        "label": "Piscine",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une piscine",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une piscine pour enfants (peut faire partie de la piscine principale)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La piscine est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La piscine est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'établissement procède à des prélèvements et des analyses journaliers de la qualité de l'eau qu'il consigne dans un fichier de suivi consultable à tout moment en cas de contrôle/classement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un contrat avec un laboratoire spécialisé pour l'analyse approfondie de la qualité de l'eau de baignade",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La piscine est nettoyée quotidiennement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Sécurité de la piscine",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les horaires de baignade sont affichés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les consignes de sécurité et d'utilisation de la piscine sont affichées et bien visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La piscine est dotée d'une bouée de sauvetage bien visible et facilement accessible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Un surveillant-sauveteur est disponible pendant toute la période de la baignade",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La profondeur de la piscine est maquée et bien visible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol des abords de la piscine est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Douches de la piscine",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Une ou plusieurs douches à proximité de la piscine sont installées",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les douches sont dotées d'un bouton-poussoir ou à détection",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le sol de la douche est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La douche est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La douche est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Mobilier de la piscine",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La piscine est dotée de parasols et de transats",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La piscine est dotée de tables basses",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les parasols, transats et tables basses sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les parasols, transats et tables basses sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Serviettes piscine",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition des serviettes de piscine",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les serviettes de piscine sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les serviettes de piscine sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 6,
            "label": "Snack-bar",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un snack-bar à proximité de la piscine",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les horaires des services du snack-bar sont affichés à un endroit bien visible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du snack-bar sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du snack-bar sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, réfrigérateur, machine à café…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les équipements de travail (comptoirs, consoles, réfrigérateur, machine à café…) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les couverts, la verrerie et la vaisselle sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les couverts, la verrerie et la vaisselle sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les tables, les assises et le linge de table (nappes, serviettes, sets…) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Existence d'un menu/carte des plats servis, spécifiant les prix applicables ainsi que tout supplément (taxe, frais de services…), propre et en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le menu/carte des plats servis est rédigé au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 7,
            "label": "Vestiaires",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La piscine dispose de vestiaires séparés entre hommes et femmes (lorsque la piscine est ouverte aux clients non-résidents à l'établissement, sinon les critères du vestiaire ne sont pas applicables)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés d'assises (bancs, chaises…)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés en crochets ou patères",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les vestiaires sont équipés de poubelles en matière inoxydable, autre que du plastique, avec couvercle. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le sol des vestiaires est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les vestiaires sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les vestiaires sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 8,
            "label": "Lavabo",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Chaque vestiaire contient au moins un lavabo ou une vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La hauteur des lavabos/vasque est comprise entre 85 et 90 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La plomberie des lavabos n'est pas visible, sauf lorsqu’elle fait partie des éléments décoratifs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont dotés d'un distributeur de savon liquide. Le savon solide est prohibé. Les flacons ou bouteilles de savon liquide en plastique ne sont pas admis",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont dotés de miroirs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La taille des miroirs est adaptée à celle du lavabo/vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Débit maximum de l'eau ne dépassant pas 8 lites/minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les lavabos/vasques sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 9,
            "label": "Cabines de toilette",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La piscine dispose d'au moins une cabine de toilette pour homme et une pour femmes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont toutes dotées d'une séparation du reste du vestiaire. Pour les catégories 5* et 4*, les portes des cabines de toilettes permettent une isolation complète de celle-ci par rapport au reste du bloc sanitaire",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'aménagement des toilettes prévoit deux espaces libres de 20 cm minimum de chaque côté et 40 cm minimum devant les WC",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les toilettes sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les toilettes sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable autre que du plastique",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "L'ensemble des cabines de toilettes disposent de poubelles en matière inoxydable, autre que du plastique, avec couvercle.L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les toilettes sont équipées d'un dispositif de rinçage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les conduits et la tuyauterie au niveau des cabines de toilettes ne sont pas visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les chasses d'eau à double débit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 12,
        "label": "Boutique",
        "sections": [
          {
            "id": 1,
            "label": "Boutique",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'une ou de plusieurs boutiques",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement assure un affichage des prix des différents objets vendus dans la boutique ainsi que toute autre taxe ou charge supplémentaire à supporter par le client",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le paiement par carte bancaire internationale est possible (au minimum deux types de cartes bancaires)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement affiche les moyens de paiement acceptés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La boutique propose des objets d'artisanat, de terroir ou d'art béninois",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La boutique est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La boutique est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          }
        ]
      },
      {
        "n": 13,
        "label": "Unités de logement",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La superficie minimale des unités de logement de l'hôtel 5 étoiles, sanitaire compris, est conforme aux ratios suivants :  20 m² pour 1 personne, 24 m² pour deux personnes, 26 m² pour trois personnes, 30 m² pour quatre personnes, 35 m² pour cinq personnes, 40 m² pour 6 personnes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La superficie minimale des unités de logement de l'hôtel 4 étoiles, sanitaire compris, est conforme aux ratios suivants :  14 m² pour 1 personne, 16 m² pour deux personnes, 18 m² pour trois personnes, 20 m² pour quatre personnes, 25 m² pour cinq personnes, 30 m² pour 6 personnes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La superficie minimale des unités de logement de l'hôtel 3 étoiles, sanitaire compris, est conforme aux ratios suivants :  12 m² pour 1 personne, 14 m² pour deux personnes, 15 m² pour trois personnes, 18 m² pour quatre personnes, 24 m² pour cinq personnes, 28 m² pour 6 personnes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La superficie minimale des unités de logement de l'hôtel 2 étoiles, sanitaire compris, est conforme aux ratios suivants :  10 m² pour 1 personne, 12 m² pour deux personnes, 13 m² pour trois personnes, 15 m² pour quatre personnes, 24 m² pour cinq personnes, 28 m² pour 6 personnes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La superficie minimale des unités de logement de l'hôtel 1 étoile, sanitaire compris, est conforme aux ratios suivants :  10 m² pour 1 personne, 12 m² pour deux personnes, 13 m² pour trois personnes, 15 m² pour quatre personnes, 24 m² pour cinq personnes, 28 m² pour 6 personnes.  La superficie minimale des unités de logement de l'hôtel 1 étoiles , lorsque les sanitaires sont communs étoiles est conforme aux ratios suivants :  9 m² pour 1 personne, 10 m² pour deux personnes, 11 m² pour trois personnes, 12 m² pour quatre personnes, 18 m² pour cinq personnes, 20 m² pour 6 personnes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Un minimum de 50% des unités de logement est réservé pour les clients non-fumeurs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les murs, le sol et les plafonds des unités de logement sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les murs, le sol et les plafonds des unités de logement sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les unités de logement sont harmonieusement décorées (exemple : tableaux, sculptures, lampadaires, etc.)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les unités de logement sont climatisées. La température doit varier entre 21 et 24 °C",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le bruit de la climatisation ne dépasse pas 30 dB ou dispose d'une fonction \"silence\" ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'un interrupteur d'éclairage central à l'entrée de l'espace d'habitation",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'un système de coupure automatique du courant lorsqu'ils ne sont pas occupés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "L'éclairage des unités de logement est assuré via des LED ou des lampes à basse consommation",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "L'établissement sensibilise les clients sur l'importance de l'optimisation de l'énergie et de l'eau",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Porte",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les portes d'entrée aux unités de logement sont équipées d'un système de verrouillage (carte magnétique, serrure, etc.) permettant une fermeture de l'intérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les portes d'entrée aux unités de logement sont équipées d'un judas",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les portes d'entrée aux unités de logement sont équipées d'une chainette ou d'un crochet",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le plan d'évacuation d'urgence est affiché derrière la porte d'entrée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les portes d'entrée des unités de logement sont dotées d'un ferme-porte (se fermant seule lorsqu'elle est laissée ouverte)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les portes d'entrée des unités de logement sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les portes d'entrée des unités de logement sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Fenêtre",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Toutes les unités de logement disposent d'une ou plusieurs fenêtres donnant vers l'extérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La fenêtre permet une bonne isolation phonique (exemple : double vitrage)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La fenêtre est dotée de rideau ou de stores",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le rideau ou les stores couvrent toute la surface des fenêtres, permettant d'avoir une obscurité parfaite lorsqu'ils sont fermés (le black-out)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La fenêtre, dont les rideaux/stores, est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La fenêtre, dont les rideaux/stores, est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Porte-bagages",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un porte-valise d'une taille suffisante pour l'utilisation d'une grande valise, correspondant au standing de l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le porte-valise est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le porte-valise est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Placard/penderie/dressing",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'une penderie",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La profondeur minimale de la penderie est de 60 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La largeur minimale de la penderie est de 1,2 m pour les chambres simples et 2,4 m pour les chambres doubles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La penderie ou une partie de celle-ci est dotée d'une porte (à l'exception des dressings aménagés)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La penderie comporte une barre pour l'accrochage des vêtements",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La penderie comporte des cintres identiques correspondant au standing de l'établissement (les cintres en plastique ne sont pas acceptés que dans les catégories 1 et 2 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le nombre de cintres est conforme aux ratios suivants : 4 cintres avec barre et 2 cintres avec pinces pour la catégorie 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Le nombre de cintres est conforme aux ratios suivants : 3 cintres avec barre et 2 cintres avec pinces pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le nombre de cintres est conforme aux ratios suivants : 3 cintres avec barre pour les catégories 3 étoiles, 2 étoiles et 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La penderie est compartimentée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La penderie comporte des tiroirs (au minimum 2 tiroirs par occupant pour la catégorie 5*) ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "La penderie comporte un coffre-fort fixé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "L'intérieur de la penderie est bien éclairé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "La penderie est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "La penderie est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 6,
            "label": "Lit",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les dimensions minimales du matelas des lits doubles de 180 cm x 200 cm pour l'hôtel 5 étoiles Les dimensions minimales du matelas des lits simples de 120 cm x 200 cm pour l'hôtel 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les dimensions minimales du matelas des lits doubles de 160 cm x 190 cm pour l'hôtel 4 étoiles  Les dimensions minimales du matelas des lits simples de 120 cm x 190 cm pour l'hôtel 4 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les dimensions minimales du matelas des lits doubles de 160 cm x 190 cm pour l'hôtel 3 étoiles Les dimensions minimales du matelas des lits simples de 70 cm x 190 cm pour l'hôtel 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les dimensions minimales du matelas des lits doubles de 140 cm x 190 cm pour l'hôtel 2 étoiles et 1 étoile Les dimensions minimales du matelas des lits simples de 70 cm x 190 cm pour l'hôtel 2 étoiles et 1 étoile",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le lit est accessible de ses deux côtés (cas des chambres doubles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le lit dispose d'une tête de lit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le lit dispose d'un sommier en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Le sommier est rembourré ou décoratif",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le lit dispose d'un matelas en bon état et de taille adaptée à celle du lit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le matelas est doté d'une alèse ou d'un protège-matelas",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le lit est équipé d'une couette (3, 4 et 5 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le lit est équipé d'une couette ou d'une couverture (les couvertures ne sont admises que pour les catégories 1 et 2)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Le lit est équipé en drap séparant la couette/couverture du lit ou drap de housse (drap plat)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Le lit dispose d'un jeté",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "La taille du linge de lit est ajustée par rapport à celle du matelas. Les draps plats dépassent de 6 à 8 cm la profondeur du matelas.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "Le lit est équipé en oreillers (au moins deux pour les lits doubles et 1 pour les lits individuels)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "Le lit est équipé d'un oreiller supplémentaire par personne",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 18,
                "input_type": "RADIO",
                "question": "L'oreiller est protégé par une taie d'oreiller",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 19,
                "input_type": "RADIO",
                "question": "L'établissement offre aux clients la possibilité de choisir parmi divers types d'oreillers",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 20,
                "input_type": "RADIO",
                "question": "L'établissement propose la possibilité de réserver un lit pour bébé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 21,
                "input_type": "RADIO",
                "question": "Le lit pour bébé est doté au minimum d'un matelas protégé, d'un oreiller adapté et d'un drap",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 22,
                "input_type": "RADIO",
                "question": "Le lit ainsi que l'ensemble de ses composantes (matelas, linge, etc.) sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 23,
                "input_type": "RADIO",
                "question": "Le lit ainsi que l'ensemble de ses composantes (matelas, linge, etc.) sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 24,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'un interrupteur d'éclairage central à accessible depuis le lit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 25,
                "input_type": "RADIO",
                "question": "Les lits sont dotés de liseuses orientables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 7,
            "label": "Table de chevet",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement disposent d'une table de chevet par couchage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le matériau, le style et les couleurs de la table de chevet sont en harmonie avec le lit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Chaque table de chevet est équipée d'une lampe de chevet",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Une prise de courant libre est disponible à proximité de chaque table de chevet (à une distance maximale de 80 cm)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Une prise USB / USB type C est disponible à proximité de chaque table de chevet (à une distance maximale de 80 cm)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La table de chevet et l'ensemble de ses composantes sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La table de chevet et l'ensemble de ses composantes sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 8,
            "label": "Coiffeuse/bureau",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'une coiffeuse ou un bureau",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'espace libre au niveau de la coiffeuse ou le bureau respecte les dimensions minimales suivantes : 40 cm x 80 cm . L'espace au-dessous de la coiffeuse/bureau est libre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La coiffeuse ou le bureau est doté d'une chaise avec dossier de hauteur adaptée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La chaise de la coiffeuse/bureau est rembourrée / dispose d'une mousse",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Une prise de courant libre est disponible à proximité de la coiffeuse/bureau (à une distance maximale de 80 cm)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Une prise USB / USB type C est disponible à proximité de la coiffeuse/bureau (à une distance maximale de 80 cm)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Existence d'un point lumineux au-dessus de la coiffeuse/bureau",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement propose un nécessaire pour écrire (feuilles, crayon/stylo…)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La coiffeuse/bureau est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La coiffeuse/bureau est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 9,
            "label": "Table et assises",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées au minimum d'une assise (chaise, fauteuil…) par occupant (autres que celles de la terrasse lorsque celles-ci existent ainsi que la chaise du bureau/coiffeuse)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'une table/table basse adaptée aux assises (autre que celle de la terrasse lorsque celle-ci existe)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les assises et la table sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les assises et la table sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 10,
            "label": "Miroir",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'unité de logement est dotée d'un miroir permettant de se voir en entier (peut également être situé dans la salle de bain)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le miroir est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le miroir est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 11,
            "label": "Boite à rebus",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'une boite à rebus/poubelle. Les poubelles en plastique ne sont acceptées que pour les catégories 1* et 2*. Dans le cas où la cigarette n'est pas prohibée, la boite à rebus/poubelle est faite d'une matière non-inflammable",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La boite à rebus est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La boite à rebus est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 12,
            "label": "Télévision",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées d'une télévision",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La télévision est située dans un endroit lui permettant d'être facilement visible des deux côtés du lit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La télévision est à écran plat et, au minimum, à haute définition (HD, 4K, 8K...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le diamètre de la télévision est supérieur ou égal à 49 pouces (environ 124 cm)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "La télévision offre la possibilité d'accéder à des chaines internationales ainsi que des chaines thématiques (minimum : 12 chaînes)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La télévision offre la possibilité d'afficher l'écran d'un téléphone, une tablette ou un ordinateur sur son écran",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Un guide/liste des chaines TV ainsi que les instructions sur l'usage de la télévision sont disponibles et bien visible/facilement accessible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'image de la télévision est de bonne qualité",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les fils électriques de la télévision sont cachés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La télévision (dont la télécommande) est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La télévision (dont la télécommande) est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 13,
            "label": "Téléphone et Internet",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement disposent d'un téléphone permettant de passer des appels internes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le téléphone permet de passer des appels externes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Un répertoire/liste des numéros utiles est disponible et bien visible aux clients",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Dans le cas où le téléphone permet de passer des appels extérieurs, les prix applicables ainsi que tout supplément sont visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le téléphone est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Téléphone est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Une connexion WIFI est disponible au niveau de l'ensemble des unités de logementavec un débit minimum descendant de 10 Mb/s",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Une connexion WIFI est disponible au niveau de l'ensemble des unités de logementavec un débit minimum descendant de 4 Mb/s",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 14,
            "label": "Minibar",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement sont dotées de minibars",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La capacité du minibar est supérieure ou égale à 40L",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le minibar est silencieux (0 dB)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le minbar est rempli",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le minibar comporte au moins quatre types de boissons",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le minbar comporte des snacks",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les prix du contenu du minibar ainsi que tout supplément ou taxe sont affichés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Le minibar est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le minibar est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 15,
            "label": "Accessoires de boissons",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement disposent d'une bouilloire",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les unités de logement disposent d'une machine à café",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'établissement fournit des doses de café et de thé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement fournit quotiennement de l'eau filtrée ou minérale",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Des tasses pour café sont disponibles au niveau des unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Des mugs pour thé sont disponibles au niveau des unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Des verres d'eau sont disponibles au niveau des unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Des verres de vin sont disponibles au niveau des unités de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La bouilloire, la machine à café et les tasses, mugs et verres sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La bouilloire, la machine à café et les tasses, mugs et verres sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 16,
            "label": "Room-directory",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les unités de logement disposent d'un room-directory (physique ou numérique de type application d'hôtel, chaine interne...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le room directory est disponible au minimum en deux langues, dont le français et l'anglais",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le room directory est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le room directory est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 17,
            "label": "Services",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Un service de ménage est assuré quotidiennement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "En cas de long séjour, le linge de lit est changé au maximum une fois tous les trois jours et après chaque check-out",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'établissement propose aux clients la possibilité de ne changer le linge de lit qu'à la demande",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement propose un roomservice, disponible 24h/24",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'établissement propose un service \"couverture\"",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "L'établissement propose un service \"nettoyage et pressing\". Dans ce cas, la liste des prix applicable, dont tout éventuel supplément, est disponible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition sur demande le nécessaire pour repasser",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 14,
        "label": "Salle de bain privative",
        "sections": [
          {
            "id": 1,
            "label": "Généralités",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'ensemble des unités de logement disposent d'une salle de bain privative",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La salle de bain est bien aérée (aération naturelle ou mécanique)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La salle de bain est bien éclairée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La salle de bain est aménagée de manière harmonieuse avec l'unité de logement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'éclairage de la salle de bain est assuré via des lampes à basse consommation ou en LED",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond de la salle de bain sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond de la salle de bain sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "L'établissement affiche des consignes permettant aux clients la réutilisation des serviettes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Lavabo",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les salles de bain disposent d'un lavabo ou une vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La hauteur du lavabo/vasque est comprise entre 85 et 90 cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La plomberie des lavabos n'est pas visible, sauf lorsqu’elle fait partie des éléments décoratifs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La taille du lavabo est suffisante pour se laver les mains et le visage confortablement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le lavabo permet de disposer de l'eau froide et chaude. L'eau chaude arrive dans un délai maximum de 10 secondes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les robinets sont équipés d'économiseurs d'eau non-visibles ou ont un débit maximum ne dépasse pas 8 lites/minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'un miroir",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La taille du miroir est adaptée à celle du lavabo/vasque",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'un point lumineux au-dessus du miroir",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'une prise de rasoir (maximum 40 cm du lavabo)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'un miroir agrandissant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'un sèche-cheveux",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Le lavabo est doté d'un porte essuie-mains",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Le lavabo et ses équipements sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "Le lavabo et ses équipements sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Lavabo",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La salle de bain dispose d'une douche",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les cabines de douche sont de dimensions minimales de 90cmx120cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les cabines de douche sont de dimensions minimales de 90cmx90cm",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La douche est dotée d'eau chaude et froide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'eau chaude de la douche arrive dans un délai maximum de 10 secondes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le sol de la douche est antidérapant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La douche est protégée par une pare-douche/porte. L'eau ne déborde pas lors de l'utilisation de la douche",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "La douche est protégée par une pare-douche/porte ou un rideau propre et en parfait état. L'eau ne déborde pas lors de l'utilisation de la douche",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La douche est de type 'à l'italienne' ",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La hauteur de la pommette d'eau est ajustable (lorsqu'elle n'est pas fixée au plafond)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La douche est dotée d'un porte-savonnette en matière inoxydable, autre que du plastique (catégories 3, 4 et 5 étoiles). A partir de la catégorie 3*, les porte-savonnette en plastique ne sont pas admis",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "La douche est dotée d'un porte-savonnette en matière inoxydable, qui peut être du plastique (1 et 2 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "La douche est dotée d'un crochet ou une patère (accessible depuis la douche)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Tous les éléments de la douche sont en matière inoxydables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "La douche est bien éclairée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "La douche est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "La douche est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Baignoire",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Les salles de bain privatives disposent d'une baignoire. La baignoire ne peut remplacer la douche que pour les catégories 1 et 2 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La baignoire est équipée d'un poignée de sécurité",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La baignoire est équipée d'une douchette à la main",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La baignoire est dotée d'eau chaude et froide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'eau chaude de la baignoire arrive dans un délai maximum de 10 secondes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "La baignoire est dotée d'un porte-savonnette",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "La baignoire est dotée d'un crochet ou une patère (accessible depuis la douche)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Tous les éléments de la baignoire sont en matière inoxydables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "La baignoire est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "La baignoire est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Autres équipements de la salle de bain",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "La salle de bain dispose d'une poubelle en matière inoxydable qui peut être du platique avec couvercle. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La salle de bain est équipée d'un rack de serviettes mural",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "La salle de bain est équipée d'un sèche-serviettes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "La salle de bain est équipée d'un fil à linge",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les équipements de la salle de bain sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les équipements de la salle de bain sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 6,
            "label": "Linge de la salle de bain",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Un drap de bain par couchage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les dimensions minimales draps de bain sont de 100 x 150 cm pour l'hôtel 5 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les dimensions minimales draps de bain sont de 70 x 140 cm pour l'hôtel 4 étoiles et 3 étoiles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Un peignoir par couchage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Un pair de chausson par couchage (dans un emballage scellé)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Un tapis de bain",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Un essuie-main par couchage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Un carré de visage par personne",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Le linge de la salle de bain est propre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Le linge de la salle de bain est en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 7,
            "label": "Services",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Le linge de la salle de bain est changé quotidiennement, sauf en cas d'existence d'une procédure permettant aux clients d'exiger son changement uniquement à la demande, et après chaque check-out",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le linge de la salle de bain est changé au minimum une fois  tous les trois jours et après chaque check-out",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 8,
            "label": "Produits d'accueil",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Une savonnette par couchage au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Un gel-douche par couchage, au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Un shampooing par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Une crème de corps par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Une lime à ongles par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Une lime à ongles par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Une lime à ongles par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Coton",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Un bonnet de douche par chambre",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Des mouchoirs jetables",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "Une éponge pour chaussures",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Un chausse-pied",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Un set dentaire par couchage , au minimum",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Un kit de rasage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 15,
                "input_type": "RADIO",
                "question": "Un kit de couture",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 16,
                "input_type": "RADIO",
                "question": "Un verre à dents protégé/emballé par couchage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 17,
                "input_type": "RADIO",
                "question": "Les produits d'accueil proposés par l'établissement sont écologiques ou issus du commerce équitable",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 9,
            "label": "Toilette",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'ensemble des unités de logement disposent d'une toilette privative",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "La toilette est séparée de la salle de bain",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'aménagement des toilettes prévoit deux espaces libres de 20 cm minimum de chaque côté et 40 cm minimum devant les WC",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable, autre que du plastique.",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'établissement met à disposition du papier hygiénique, dans un support en matière inoxydable, qui peut être du plastique (seulement les catégories 1 et 2 étoiles)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Mise à disposition de papier hygiénique supplémentaire, dans un support",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Lorsqu'elle est séparée de la salle de bain, la cabine de toilette dispose d'une poubelleavec couvecle, en matière inoxydable autre que du plastique. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Lorsqu'elle est séparée de la salle de bain, la cabine de toilette dispose d'une poubelle avec couvecle, en matière Inoxydable qui peut être du plastique. L'usage des poubelles doit être possible sans les toucher (exemple : poubelle à pédale, à volet abattant...)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Les toilettes sont dotées de distributeurs de sachets hygiéniques",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 10,
                "input_type": "RADIO",
                "question": "Les conduits et la tuyauterie au niveau des cabines de toilettes ne sont pas visibles",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "NOT_APPLICABLE",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 11,
                "input_type": "RADIO",
                "question": "La chasse d'eau est à double débit",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 12,
                "input_type": "RADIO",
                "question": "Les toilettes sont équipées d'un dispositif de rinçage",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 13,
                "input_type": "RADIO",
                "question": "Les toilettes sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 14,
                "input_type": "RADIO",
                "question": "Les toilettes sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 15,
        "label": "Espace \"Personnel\"",
        "sections": [
          {
            "id": 1,
            "label": "Vestiaire du personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un vestiaire de personnel séparé entre hommes et femmes",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Les murs, le sol et les plafonds du vestiaire sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les murs, le sol et les plafonds du vestiaire sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Le vestiaire de personnel comporte des casiers individuels, pouvant être fermés à clé",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le nombre de casiers est au minimum équivalent à 50% du nombre total du personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le nombre de casiers est équivalent à 100% du nombre total du personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les casiers sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Les casiers sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Lavabos",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose de lavabos dédiés au personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre de lavabos est équivalent à 1 pour 10 employés (maximum de 10 lavabos)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les robinets des lavabos sont à détection ou équipés de boutons-poussoirs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les lavabos sont dotés de miroirs",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les lavabos sont dotés de savon liquide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les lavabos sont dotés de sèche-mains",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 3,
            "label": "Toilettes du personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose de toilettes dédiées au personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre de toilettes est équivalent à 1 pour 10 employés (maximum 10 cabines)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont dotées d'un dispositif de verrouillage de l'intérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les cabines de toilettes sont dotées de papier toilette",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Les toilettes du personnel sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les toilettes du personnel sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les chasses d'eau sont économiques (max. 6l)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 4,
            "label": "Douches du personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose de douches dédiées au personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le nombre de douches est équivalent à 1 pour 10 employés (maximum de 10 douches)",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Les cabines de douche du personnel sont dotées d'un dispositif de verrouillage de l'intérieur",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Les douches du personnel sont équipées en porte-savonnette",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le débit des douches ne dépasse pas 9 litres par minute",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Les douches du personnel sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Les douches du personnel sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 5,
            "label": "Réfectoire du personnel",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un réfectoire du personnel",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le réfectoire de personnel est doté de tables à manger et de chaises",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Le nombre de chaises équivalents à 1/3 du nombre d'employés",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "Un lavabo à proximité du réfectoire est disponible",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "Le lavabo est doté de savon liquide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Le lavabo est équipé en sèche-main",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 7,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du réfectoire sont propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 8,
                "input_type": "RADIO",
                "question": "Le sol, les murs et le plafond du réfectoire sont en bon état",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 9,
                "input_type": "RADIO",
                "question": "Equipements du réfectoire propres",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      },
      {
        "n": 16,
        "label": "Electricité, eau et assainissement",
        "sections": [
          {
            "id": 1,
            "label": "Energie",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "L'établissement dispose d'un groupe électrogène, se déclenchant automatiquement en cas de coupure du courant",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "Le groupe électrogène est de capacité suffisante pour couvrir l'ensemble des besoins de l'établissement",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "Une partie de l'énergie utilisée par l'établissement est renouvelable",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 2
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": true
          },
          {
            "id": 2,
            "label": "Eau",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Dans le cas où l'établissement n'est pas connecté au réseau de distribution de l'eau potable, il dispose d'un réservoir d'eau ou un forage couvrant l'ensemble de ses besoins",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "En cas d'existence d'un puit, celui-ci est situé à une distance minimale de 15 mètres de tout dispositif d'assainissement liquide",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 3,
                "input_type": "RADIO",
                "question": "L'eau du forage/réservoir régulièrement traitée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 4,
                "input_type": "RADIO",
                "question": "L'eau du forage/réservoir régulièrement analysée",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 5,
                "input_type": "RADIO",
                "question": "L'établissement a mis en place un dispositif de récupération des eaux de la pluie",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 6,
                "input_type": "RADIO",
                "question": "Utilisation de produits de lavage écologiques",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          },
          {
            "id": 2,
            "label": "Assainissement",
            "total_points": {
              "total_responded_questions": null
            },
            "questions": [
              {
                "id": 1,
                "input_type": "RADIO",
                "question": "Dans le cas où l'établissement n'est pas connecté au réseau de d'assainissement public, une solution alternative adaptée est mise en place",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 3
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "REQUIRED",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MAJOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              },
              {
                "id": 2,
                "input_type": "RADIO",
                "question": "L'établissement a mis en place un dispositif de récupération et réutilisation des eaux usées",
                "responses": [
                  {
                    "id": 1,
                    "label": "Oui",
                    "value": 1
                  },
                  {
                    "id": 2,
                    "label": "Non",
                    "value": 0
                  }
                ],
                "selected_id": null,
                "metadata": [
                  {
                    "type": "OPTIONAL",
                    "is_required": true,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "1"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "2"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "3"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "4"
                  },
                  {
                    "type": "MINOR",
                    "is_required": false,
                    "to_justify": false,
                    "to_test": false,
                    "rank": "5"
                  }
                ]
              }
            ],
            "isOpen": false
          }
        ]
      }
    ]},
      },
      isLoading: false,
      filterBy: {
        type_auto_evaluation_id: null,
        secteur_id: null,
        type: null,
      },
      options_type_secteur: [],
      options_type_evaluation: [
        { id: 1, title: 'Évaluation', value: 'evaluation' },
        { id: 2, title: 'Examen', value: 'examen' },
      ],
      tableData: [],
      tableColumns: [
        {
          key: 'title',
          label: 'Libellé',
          sortable: true,
        },
        {
          key: 'secteur_id',
          label: 'Secteur',
          sortable: true,
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Date',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      typeAutoEvaluationData: [],
      listSectorsData: [],
      configs: [
        {
          id: null,
          code: 'login.configuration.enable_otp',
          value: false,
        },
        {
          id: null,
          code: 'concours.configuration.enable',
          value: false,
        },
        {
          id: null,
          code: 'config.fedapay.key',
          value: '',
        },
        {
          id: null,
          code: 'config.kkiapay.key',
          value: '',
        },
      ],
      loadingUpdateConfig: false,
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },

      selectedOption: '',
      snowOption: {
        theme: 'snow',
      },
      editorOption: {
        theme: 'snow',
        // modules: {
        //   toolbar: '#quill-toolbar',
        // },
        placeholder: '',
      },
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(_val, _old) {
        this.load_data()
        //this.getListTypeHebergement()
        this.loadSectors()
      },
    },
    perPage: {
      immediate: true,
      handler(val, _old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.tableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    'paginationData.currentPage': {
      handler(_val, _old) {
        this.load_data()
      },
    },
    'paginationData.perPage': {
      handler(_val, _old) {
        this.load_data()
      },
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: 'evaluations', module: evaluationsStoreModule },
      { path: 'secteurs', module: secteursStoreModule },
      { path: 'etablissements', module: etablissementsStroreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  mounted() {
    /*  this.getAllEtablissementTypeList()
    this.getAllTypeEvaluation()
    this.getAllFormEvaluation() */
    this.loadSectors()
  },
  methods: {
    ...mapMutations('app-configurations', {
      action_setter_passForgotAccess: 'setter_passForgotAccess',
    }),
    ...mapActions('secteurs', {
      action_fetchSecteurs: 'fetchSecteurs',
    }),
    ...mapActions('evaluations', {
      action_searchTypeEvaluation: 'searchTypeEvaluation',
      action_getTypeEvaluation: 'getTypeEvaluation',
      action_addEvaluation: 'addEvaluation',
      action_allTypeEvaluation: 'allTypeEvaluation',
      action_allEvaluation: 'allEvaluation',
      action_searchFormEvaluation: 'searchFormEvaluation',
      action_updateFormEvaluation: 'updateFormEvaluation',
    }),
    ...mapActions('etablissements', {
      action_searchTypeEtablissement: 'searchTypeEtablissement',
      action_getListTypesEtablissement: 'allEtablissementList',
    }),
    // import actions
    ...mapActions('app-configurations', {
      action_getAppConfiguration: 'getAppConfiguration',
      action_searchAppConfiguration: 'searchAppConfiguration',
      action_updateAppConfiguration: 'updateAppConfiguration',
    }),
    // ------------------------------------------------------------------
    // Load type hebergment
    /* getListTypeHebergement() {
      this.action_searchTypeEtablissement({ secteur_id: 2 })
        .then(res => {
          this.options_type_secteur = res.data.data || []
        })
        .catch(_err => { })
    }, */
    // Load data
    load_data() {
      try {
        this.isLoading = true
        const params = {
          ...this.filterBy,
          page: this.paginationData.currentPage - 1,
          perPage: this.paginationData.perPage,
        }
        // console.log("searchEvaluation params::: ⭕️", params);
        // return;
        this.action_searchTypeEvaluation(params)
          .then(async response => {
            if (response.status === 200) {
              this.tableData = response.data.data
              this.isLoading = false
              // --------------------------------------------------------------
              // this.paginationData.total = response.data.meta.total || 0;
              // if (this.paginationData.metaData.from === 0) {
              //   this.paginationData.metaData.from += 1;
              // } else {
              //   this.paginationData.metaData.from =
              //     this.paginationData.perPage *
              //       this.paginationData.currentPage -
              //     this.paginationData.perPage;
              // }
              // this.paginationData.metaData.to =
              //   this.paginationData.perPage * this.paginationData.currentPage -
              //   this.paginationData.perPage +
              //   response.data.meta.total;
              // --------------------------------------------------------------
            }
          })
          .catch(error => {
            this.isLoading = false
            console.error(
              'Erreur lors de la recherche des auto-évaluations.',
              error,
            )
          })
      } catch (error) {
        this.isLoading = false
        console.error(
          'Erreur lors de la recherche des auto-évaluations.',
          error,
        )
      }
    },
    // Chargement type d'auto-evalautions
    // loadAutoEvaluationType() {
    //   this.isFetchingTypeAutoEval = true;
    //   this.action_searchTypeEvaluation({ type: "evaluation" })
    //     .then(async (response) => {
    //       if (response.status === 200) {
    //         this.typeAutoEvaluationData = response.data.data || [];
    //         this.isFetchingTypeAutoEval = false;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err::: ", err);
    //       this.isFetchingTypeAutoEval = false;
    //     });
    // },

    // Chargement secteurs
    loadSectors() {
      this.isFetchingSectors = true
      this.action_fetchSecteurs()
        .then(async response => {
          if (response.status === 200) {
            this.listSectorsData = response.data.data || []
            this.isFetchingSectors = false
          }
        })
        .catch(err => {
          console.log('err::: ', err)
          this.isFetchingSectors = false
        })
    },
    // eslint-disable-next-line camelcase
    go_to_detail(id, type_hebergement_id) {
      //console.log('jojo', id)
      //console.log('kojo', type_hebergement_id)
      let form = {}
      // check if have valide form
      this.action_getTypeEvaluation(id)
        .then(async response => {
          if (response.status === 200) {
            let form =  response.data.formulaires.filter(item => item.type_etablissement_id == type_hebergement_id)
            //console.log('kole', form[0].data == null )
            //form = this.find_correct_form(response.data, type_hebergement_id)
            if (form[0].data == null) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Aucun formulaire associé',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              this.$router.push({
                name: 'config-forms_details',
                params: { id, type_hebergement_id },
              })
            }
          }
        })
        .catch(error => {
          console.log('error::: ', error)
        })
    },
    
    // Changement de page
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage
      this.paginationData.perPage = val.perPage
    },

    createEvaluation() {
      console.log('submitted', this.evaluation)
      console.log(this.evaluation)
      if (this.evaluation.type_auto_evaluation_id == null || this.evaluation.type_etablissement_id == null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Veuillez remplir tous les champs.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
      } else {
        this.action_searchFormEvaluation({ type_auto_evaluation_id: this.evaluation.type_auto_evaluation_id, type_etablissement_id: this.evaluation.type_etablissement_id })
          .then(res => {
            this.searchFormEvaluationData = res.data.data
            // eslint-disable-next-line eqeqeq
            console.log('this.searchFormEvaluationData', this.searchFormEvaluationData.length)
            if (this.searchFormEvaluationData.length !== 0) {
              this.$refs.myModal.show()
            } else {
              this.action_addEvaluation(this.evaluation)
                .then(res => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Formulaire créé avec succès !',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  setTimeout(() => {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload()
                  }, 3000)
                })
                .catch(err => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: err.message || 'Une erreur est survenue',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                    },
                  })
                })
            }
          })
          .catch(err => {
            console.log(err)
          })
      }
    },

    updateFormEvaluation() {
      console.log('mise a jour bien')
      console.log('old', this.searchFormEvaluationData[0].id)
      this.action_updateFormEvaluation({ id: this.searchFormEvaluationData[0].id, params: { type_auto_evaluation_id: this.evaluation.type_auto_evaluation_id, type_etablissement_id: this.evaluation.type_etablissement_id } })
        .then(res => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Mise à jour avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload()
          }, 3000)
        })
        .catch(err => {
          console.log(err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    searchTypeEtablissement() {
      this.action_searchTypeEtablissement({ secteur_id: this.secteurId })
        .then(response => {
          this.allTypeEtablissement = response.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    searchTypeEvaluation() {
      this.action_searchTypeEvaluation({ secteur_id: this.secteurId })
        .then(res => {
          this.allTypeEvaluation = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    handleSecteurChange() {
      this.searchTypeEtablissement()
      this.searchTypeEvaluation()
    },

  },
}

</script>
<style scoped>
::v-deep .nav-pills {
  margin-bottom: 0px;
  margin-left: 1rem;
  margin-right: 1rem;
}

::v-deep .nav-pills .nav-link {
  color: white !important;
}

::v-deep .nav-pills .nav-link.active {
  color: #000000 !important;
}
</style>
